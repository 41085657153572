import DateRangeIcon from '@mui/icons-material/DateRange';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ReportIcon from '@mui/icons-material/Report';
import EvStationIcon from '@mui/icons-material/EvStation';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import { Link } from 'react-router-dom';
import { useUser } from '../components/auth';
import '../css/dashboard.css';

export default function Dashboard() {
  const user = useUser();
  const { type } = user;
  return (
    <>
      <section className="d-header">
        <h4>Dashboard</h4>
        <div className="date-section">
          <input name="d-date" id="d-date" type="date" />
          <DateRangeIcon className="date-icon" />
        </div>
      </section>
      <article className="d-body">
        {/* Dashboard sections starts here */}

        {(type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/cars">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Cars</h5>
                  {/* <h3>----</h3> */}
                </div>
                <div className="icon-cover">
                  <AirportShuttleIcon
                    className="body-icons"
                    sx={{ fontSize: 40 }}
                  />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {/*  */}

        {(type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/ACgroup">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>AC Group</h5>
                  {/* <h3></h3> */}
                </div>
                <div className="icon-cover">
                  <FormatListBulletedIcon
                    className="body-icons"
                    sx={{ fontSize: 40 }}
                  />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {/*  */}

        {(type === 'Bus stop' || type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/bus-stops">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Bus Stops</h5>
                  {/* <h3>--------</h3> */}
                </div>
                <div className="icon-cover">
                  <BusAlertIcon className="body-icons" sx={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {/*  */}

        {(type === 'Garage' || type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/garage">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Garages</h5>
                  {/* <h3>-------</h3> */}
                </div>
                <div className="icon-cover">
                  <CarRepairIcon className="body-icons" sx={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {/*  */}

        {(type === 'Car wash' ||
          type === 'Bus stop' ||
          type === 'HQ' ||
          type === 'Admin') && (
          <section>
            <Link className="d-links" to="/carwash">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Carwashs</h5>
                  {/* <h3>--------</h3> */}
                </div>
                <div className="icon-cover">
                  <LocalCarWashIcon
                    className="body-icons"
                    sx={{ fontSize: 40 }}
                  />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {/*  */}

        {(type === 'Fuel' ||
          type === 'Bus stop' ||
          type === 'HQ' ||
          type === 'Admin') && (
          <section>
            <Link className="d-links" to="/fuel">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Fuel</h5>
                  {/* <h3>----</h3> */}
                </div>
                <div className="icon-cover">
                  <EvStationIcon className="body-icons" sx={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {/*  */}

        {(type === 'Parcels' || type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/parcels">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Parcels</h5>
                  {/* <h3>------</h3> */}
                </div>
                <div className="icon-cover">
                  <LocalPostOfficeIcon
                    className="body-icons"
                    sx={{ fontSize: 40 }}
                  />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {/*  */}

        {(type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/reports">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Reports</h5>
                  {/* <h3>------</h3> */}
                </div>
                <div className="icon-cover">
                  <ReportIcon className="body-icons" sx={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {(type === 'Bus stop' || type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/spent">
              <div className="d-upper">
                <div className="d-titles">
                  <h5> Car's Depenses</h5>
                  {/* <h3></h3> */}
                </div>
                <div className="icon-cover">
                  <ReportIcon className="body-icons" sx={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {(type === 'Bus stop' || type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/other-spent">
              <div className="d-upper">
                <div className="d-titles">
                  <h5> Other Depenses</h5>
                  {/* <h3></h3> */}
                </div>
                <div className="icon-cover">
                  <ReportIcon className="body-icons" sx={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}

        {(type === 'Bus stop' || type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/ration">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Ration(Yabakozi)</h5>
                  {/* <h3></h3> */}
                </div>
                <div className="icon-cover">
                  <ReportIcon className="body-icons" sx={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}
        {(type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/drivers">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Drivers</h5>
                  {/* <h3></h3> */}
                </div>
                <div className="icon-cover">
                  <ReportIcon className="body-icons" sx={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}
        {(type === 'Bus stop' || type === 'HQ' || type === 'Admin') && (
          <section>
            <Link className="d-links" to="/workers">
              <div className="d-upper">
                <div className="d-titles">
                  <h5>Abakozi</h5>
                  {/* <h3></h3> */}
                </div>
                <div className="icon-cover">
                  <ReportIcon className="body-icons" sx={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="d-down">
                <p>
                  {/* <span className="d-green"> More</span> */}
                  <span className="d-n-color"> Details</span>
                </p>
              </div>
            </Link>
          </section>
        )}
      </article>
    </>
  );
}
